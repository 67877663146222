import { ACTIONS, routePermissions, SUBJECTS } from "@/libs/acl/config"
import { getUserData } from "@/auth/utils"
import { can } from "@/libs/acl/ability"

export default [
  {
    path: '/:lang/admins',
    name: 'admins',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/admin/admin-list/AdminList'),
    meta: {
      pageTitle: 'Admins',
      requireAuth: true,
    },
  },
  {
    path: '/:lang/admins/:id',
    name: 'admin-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/admin/admin-edit/AdminEdit'),
    meta: {
      requireAuth: true,
      pageTitle: 'Admin user account',
      breadcrumb: [
        {
          text: 'Admin',
          to: '/admins',
        },
        {
          text: 'Edit admin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/users',
    name: 'users',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/user/user-list/UserList'),
    meta: {
      pageTitle: 'Users',
      requireAuth: true,
    },
  },
  {
    path: '/:lang/user/:id',
    name: 'user-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/user/user-edit/UserEdit'),
    meta: {
      pageTitle: 'User account',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Edit user',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/encyclopedia',
    name: 'encyclopedia',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/encyclopedia/Encyclopedia'),
    meta: {
      pageTitle: 'Encyclopedia',
      requireAuth: true,
    },
  },
  {
    path: '/:lang/presentations',
    name: 'presentations',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/presentations/Presentations'),
    meta: {
      pageTitle: 'Presentations',
      requireAuth: true,
    },
  },

  {
    path: '/:lang/encyclopedia/new',
    name: 'add-new-encyclopedia',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/encyclopedia/EncyclopediaAddNew'),
    meta: {
      pageTitle: 'Encyclopedia',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Encyclopedia',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/encyclopedia/:id',
    name: 'encyclopedia-edit',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/encyclopedia/EncyclopediaEdit'),
    meta: {
      pageTitle: 'Encyclopedia',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Encyclopedia',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // Content
  {
    path: '/:lang/cms',
    name: 'cms',
    redirect: { name: 'category' },
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/category/CategoryList.vue'),
    meta: {
      pageTitle: 'Categories',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/cms/classifiers/category',
    name: 'category',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/category/CategoryList.vue'),
    meta: {
      pageTitle: 'Categories',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Categories',
          active: true,
        },
      ],
    },
  },

  {
    path: '/:lang/cms/classifiers/category/sub-category/:id',
    name: 'sub-category',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/subCategory/SubCategoryList'),
    meta: {
      pageTitle: 'Subcategory',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Categories',
          to: '/:lang/cms/classifiers/category',
        },
        {
          text: 'Subcategory',
          active: true,
        },
      ],
    },
  },

  // {
  //   path: '/:lang/cms/attribute',
  //   name: 'attribute',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/views/pages/cms/attribute/AttributeList.vue'),
  //   meta: {
  //     pageTitle: 'Attributes',
  //     requireAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Content management',
  //       },
  //       {
  //         text: 'Attributes',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: '/:lang/cms/tag',
  //   name: 'tag',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/views/pages/cms/tag/TagList.vue'),
  //   meta: {
  //     pageTitle: 'Tags',
  //     requireAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Content management',
  //       },
  //       {
  //         text: 'Tags',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: '/:lang/cms/history-period',
  //   name: 'history-period',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/views/pages/cms/history-period/HistoryPeriodList.vue'),
  //   meta: {
  //     pageTitle: 'History period',
  //     requireAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Content management',
  //       },
  //       {
  //         text: 'History period',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: '/:lang/cms/region',
  //   name: 'region',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/views/pages/cms/region/RegionList.vue'),
  //   meta: {
  //     pageTitle: 'Regions',
  //     requireAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Content management',
  //       },
  //       {
  //         text: 'Regions',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  {
    path: '/:lang/cms/educational/country',
    name: 'country',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/country/CountryList'),
    meta: {
      pageTitle: 'School countries',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Countries',
          active: true,
        },
      ],
    },
  },

  // {
  //   path: '/:lang/cms/encik-country',
  //   name: 'encik-country',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/views/pages/cms/ecik-country/EncikCountryList'),
  //   meta: {
  //     pageTitle: 'Encyclopedia countries',
  //     requireAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Content management',
  //       },
  //       {
  //         text: 'Encyclopedia countries',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: '/:lang/cms/historical-country',
  //   name: 'historical-country',
  //   // eslint-disable-next-line import/extensions
  //   component: () => import('@/views/pages/cms/historicalCountry/HistoricalCountryList'),
  //   meta: {
  //     pageTitle: 'Historical countries',
  //     requireAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Content management',
  //       },
  //       {
  //         text: 'Historical countries',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  {
    path: '/:lang/cms/educational/subject',
    name: 'subject',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/subject/SubjectList'),
    meta: {
      pageTitle: 'Subjects',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Subjects',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/cms/educational/subject/settings',
    name: 'subject-settings',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/subject/subjectSettings/SubjectSettingsList.vue'),
    meta: {
      pageTitle: 'Settings',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Subjects',
          active: true,
        },
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/cms/educational/grade',
    name: 'grade',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/grade/GradeList'),
    meta: {
      pageTitle: 'Grade',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Grade',
          active: true,
        },
      ],
    },
  },

  {
    path: '/:lang/cms/educational/state',
    name: 'state',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/state/StateList.vue'),
    meta: {
      pageTitle: 'State',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'State',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/cms/educational/city',
    name: 'city',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/city/CityList'),
    meta: {
      pageTitle: 'School communities',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'School communities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/cms/educational/school',
    name: 'school',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/cms/school/SchoolList'),
    meta: {
      pageTitle: 'Schools',
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Content management',
        },
        {
          text: 'Schools',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:lang/analytics',
    name: 'analytics',
    redirect: { name: 'analyticsUsers' },
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/analytics/UsersAnalytics'),
    meta: {
      pageTitle: 'Analytics',
      requireAuth: true,
    },
  },
  {
    path: '/:lang/analytics/users',
    name: 'analyticsUsers',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/analytics/UsersAnalytics'),
    meta: {
      pageTitle: 'Analytics',
      requireAuth: true,
      tabs: [
        {
          title: 'Users',
          route: { name: 'analyticsUsers' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.USER_ANALYTICS),
        },
        {
          title: 'Schools',
          route: { name: 'analyticsSchools' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.SCHOOL_ANALYTICS),
        },
        {
          title: 'Tables',
          route: { name: 'analyticsTables' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.CUSTOM_TABLE_ANALYTICS),
        },
      ],
    },
  },
  {
    path: '/:lang/analytics/schools',
    name: 'analyticsSchools',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/analytics/SchoolsAnalytics'),
    meta: {
      pageTitle: 'Analytics',
      requireAuth: true,
      tabs: [
        {
          title: 'Users',
          route: { name: 'analyticsUsers' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.USER_ANALYTICS),
        },
        {
          title: 'Schools',
          route: { name: 'analyticsSchools' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.SCHOOL_ANALYTICS),
        },
        {
          title: 'Tables',
          route: { name: 'analyticsTables' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.CUSTOM_TABLE_ANALYTICS),
        },
      ],
    },
  },
  {
    path: '/:lang/analytics/tables',
    name: 'analyticsTables',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/analytics/CustomTableWrapper.vue'),
    meta: {
      pageTitle: 'Tables',
      requireAuth: true,
      tabs: [
        {
          title: 'Users',
          route: { name: 'analyticsUsers' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.USER_ANALYTICS),
        },
        {
          title: 'Schools',
          route: { name: 'analyticsSchools' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.SCHOOL_ANALYTICS),
        },
        {
          title: 'Tables',
          route: { name: 'analyticsTables' },
          isAvailable: () => !can(ACTIONS.VIEW, SUBJECTS.CUSTOM_TABLE_ANALYTICS),
        },
      ],
    },
  },
  {
    path: '/:lang/topics',
    name: 'topics',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/topics/Topics'),
    meta: {
      pageTitle: 'Topics & Lessons',
      requireAuth: true,
    },
  },
  {
    path: '/:lang/micro-article',
    name: 'micro-article',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/react-wrapper/ReactWrapper.vue'),
    meta: {
      pageTitle: 'Micro-articles',
    },
  },
  {
    path: '/:lang/micro-article/:id',
    name: 'micro-article-item',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/react-wrapper/ReactWrapper.vue'),
    meta: {
      pageTitle: 'Micro Article',
    },
  },
  {
    path: '/:lang/icon-management',
    name: 'icon-management',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/react-wrapper/ReactWrapper.vue'),
    meta: {
      pageTitle: 'Icon Management',
      requireAuth: true,
    },
  },
  {
    path: '/:lang/gis/editor',
    name: 'editor',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/gis/GIS'),
    meta: {
      pageTitle: 'GIS Editor',
      requireAuth: true,
    },
  },
  {
    path: '/:lang/gis/style-editor',
    name: 'style-editor',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/pages/react-wrapper/ReactWrapper.vue'),
    meta: {
      pageTitle: 'GIS Style Editor',
      requireAuth: true,
    },
  },
]
